/* eslint-disable @next/next/no-img-element */
import type { NextPage } from 'next'
import Link from 'next/link'

import { Row, Col, Typography, Button, Card, Space } from '@douyinfe/semi-ui'

import { Element, scroller } from 'react-scroll'

import { FormattedMessage } from 'react-intl'

import { useSmallScreen, useWindowSize } from 'hooks/useResponsive'
import QuickIndexing from 'components/quickIndexing'
import GlobalLayout from 'components/globalLayout'

const Home: NextPage = () => {
  const { Paragraph, Title } = Typography
  const { width } = useWindowSize()
  const isSmallScreen = useSmallScreen()

  return (
    <GlobalLayout title='MFPapers - Ultimate indexing tool for CIE past papers'>
      <Row type="flex" style={{
        flexDirection: 'row-reverse',
        marginBottom: 30,
        position: 'relative'
      }}>
        <div>
          <div className="circle">
            <div className="blue"></div>
            <div className="red"></div>
            <div className="green"></div>
            <div className="yellow"></div>
            <div className="blue"></div>
            <div className="red"></div>
            <div className="green"></div>
            <div className="yellow"></div>
          </div>
        </div>
        <Col md={{ span: 24 }} lg={{ span: 12, pull: 1 }} >
          <img width="100%" src="https://mfpapers.oss-cn-hangzhou.aliyuncs.com/img/illustration-1.jpg" alt='illustration1' />
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 8, pull: 2 }} style={{ zIndex: 2 }}>
          <Row type='flex' align='middle' style={{ height: '100%', padding: 10 }}>
            <div style={{ textAlign: (isSmallScreen) ? 'center' : 'start' }}>
              <Title style={{
                fontWeight: 700,
                fontSize: '48px',
                lineHeight: '48px'
              }} >
                MFPAPERS
              </Title>
              <br />
              <Paragraph style={{ fontSize: 18, lineHeight: '32px' }} spacing="extended">
                <FormattedMessage
                  id='bxW+qn'
                  description="mfpapers subtitle"
                  defaultMessage="Blazingly fast indexing tool designed for past papers of Cambridge IGCSE and International AS & A Levels Examination"
                />
              </Paragraph>
              <br />
              <Paragraph style={{ fontSize: 18, lineHeight: '32px' }} spacing="extended">
                <FormattedMessage
                  id="i/ux7t"
                  defaultMessage="Sign up to unlock more customized features and resources"
                />
              </Paragraph>
              <br />
              <Button theme='solid' className='extra-large-button' onClick={() => {
                scroller.scrollTo('quick-indexing', {
                  duration: 1000,
                  delay: 100,
                  smooth: true,
                })
              }}
              >
                <FormattedMessage
                  id='aHsK1w'
                  description="get started"
                  defaultMessage="Get Started"
                />
              </Button>
            </div>
          </Row>
        </Col>
      </Row>
      <Element name="quick-indexing" />
      <Row
        style={{
          paddingBottom: 30,
          backgroundImage: "radial-gradient( circle 588px at 31.7% 40.2%,  rgba(225,200,239,1) 21.4%, rgba(163,225,233,1) 57.1% )",
        }}>
        <Title
          heading={1}
          style={{
            margin: 30,
            textAlign: 'center',
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '44px'
          }}>
          <FormattedMessage
            id='AJLYY3'
            description="quick indexing"
            defaultMessage="Quick Indexing"
          />
        </Title>
        <Row type="flex" style={{
          justifyContent: 'center',
        }}>
          <QuickIndexing width={isSmallScreen ? Math.min(340, width - 35) : 450} />
        </Row>
      </Row>
      <Row>
        <Title
          heading={1}
          style={{
            margin: 30,
            textAlign: 'center',
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '44px'
          }}
        >
          <FormattedMessage
            id='Cwxl9z'
            description="browse papers"
            defaultMessage="Browse Papers"
          />
        </Title>
      </Row>
      <Row style={{ marginBottom: 40 }}>
        <Col md={{ span: 24 }} lg={{ span: 8, offset: 4 }} >
          <div style={{ maxWidth: 350, margin: '30px auto' }}>
            <Link href="/browser?programID=1">
              <a>
                <Card
                  shadows='always'
                  header={
                    <Title heading={3} style={{ textAlign: 'center' }}>Cambridge IGCSE</Title>
                  }
                >
                  <img width="100%" src="https://mfpapers.oss-cn-hangzhou.aliyuncs.com/img/illustration-2.jpg" alt='illustration2' />
                </Card>
              </a>
            </Link>
          </div>
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 8 }}>
          <div style={{ maxWidth: 350, margin: '30px auto' }}>
            <Link href="/browser?programID=2">
              <a>
                <Card
                  shadows='always'
                  header={
                    <Title heading={3} style={{ textAlign: 'center' }}>Cambridge AS & A Levels</Title>
                  }
                >
                  <img width="100%" src="https://mfpapers.oss-cn-hangzhou.aliyuncs.com/img/illustration-3.jpg" alt='illustration3' />
                </Card>
              </a>
            </Link>
          </div>
        </Col>
      </Row>
    </GlobalLayout>
  );
}

export default Home
